import type { SVGProps } from 'react';

export function RightArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.125 0.5L5.625 5L1.125 9.5"
        stroke="#86909C"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
