import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import { AntDesignFilterFilled } from '@/assets/icons/funds/AntDesignFilterFilled';
import BasePullDownToRefresh from '@/components/base/basePullDownToRefresh';
import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseSift from '@/components/base/baseSift';
import BaseTopNav from '@/components/base/baseTopNav';
import { FeaturesDateTime } from '@/components/features/featuresDateTime';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import { toast } from '@/layouts/components/ToastMessage';
import { aiOrderListApi, earlySettlementApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import useUrlState from '@ahooksjs/use-url-state';
import { Divider, useDisclosure } from '@nextui-org/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AiOderCardItem from './components/AiOderCardItem';
import PageEnum from '@/enums/pageEnum';
import { history } from "@umijs/max"
// 理财列表
enum FINANCIAL_MANAGEMENT_LIST_ENUM {
  // 量化理财
  QUANTIFICATION_FINANCIAL_MANAGEMENT = '0',
  // 定期理财
  REGULAR_FINANCIAL_MANAGEMENT = '1',
}
export default () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [urlState] = useUrlState() || {};

  //审核中和已通过充值
  const [statusOption] = useState([
    {
      text: t('全部'),
      value: undefined,
    },

    {
      text: t('待结算'),
      value: 1,
    },
    {
      text: t('已结算'),
      value: 0,
    },
  ]);
  const [currentStatus, setCurrentStatus] = useState(statusOption[0]);
  const [time, setTime] = useState([]);
  const [refresh, setRefresh] = useState(0); // 刷新
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const params = useMemo(() => {
    return {
      walletType: 2,
      status: currentStatus?.value,
      startTime: time.length > 0 ? encodeURI(time[0] + 'T00:00:00') : undefined,
      endTime: time.length > 0 ? encodeURI(time[1] + 'T23:59:59') : undefined,
      financeType: urlState?.financeType,
      ...pageParams,
    };
  }, [time, pageParams, currentStatus, refresh]);

  const {
    list,
    loading: loadingDisplay,
    loadMore,
    error,
    total,
    getList: fetchList,
    ...resetPageProps
  } = usePaginationPage({
    fn: aiOrderListApi,
    params,
    setParams: setPageParams,
  });
  //
  const { run: earlySettlementReq } = useReq(earlySettlementApi, {
    manual: true,
    onSuccess: (res: any) => {
      toast.success(t('结算已提前，正在处理中，请稍候。'));
      // 重新获取数据
      setRefresh((pev) => pev + 1);
    },
  });

  // 点击结算
  const handleClickEarlySettlement = (data: any) => {
    earlySettlementReq({ id: data?.id });
  };
  return (
    <BasePullDownToRefresh
      refreshFn={async () => {
        await fetchList({ ...(params || {}), pageNo: 1 });
      }}
    >
      <div>
        <BaseTopNav
          title={t('理财交易记录')}
          rightNode={
            <>
              {/* 加载 */}
              {loadingDisplay && (
                <div className="flex items-center justify-center w-fit h-full mr-4">
                  <SvgSpinnersBarsRotateFade />
                </div>
              )}
            </>
          }
        />
        <div className="px-4">
          <div className="flex justify-between relative">
            <div className="flex">
              <BaseSift
                options={statusOption as any}
                onChange={setCurrentStatus as any}
                current={currentStatus}
                title={t('状态')}
              />
            </div>

            <AntDesignFilterFilled
              className="text-iconFontColor text-base absolute right-0 cursor-pointer"
              onClick={onOpen}
            />
          </div>

          {/*列表*/}
          <div className="pt-[8px]">
            <PullToRefresh
              loadMore={loadMore}
              total={total}
              list={list}
              error={error}
              loading={loadingDisplay}
              getList={fetchList}
              loadingDisplay={false}
              {...resetPageProps}
            >
              {list.map((item: any) => {
                return (
                  <div key={item?.id}>
                    <AiOderCardItem
                      item={item}
                      clickEarlySettlement={handleClickEarlySettlement}
                      isShowRightIcon={![null,undefined].includes(item?.financeType)&&+item?.financeType===+FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT}
                      clickOnTheCard={(data) => {
                        if(![null,undefined].includes(item?.financeType)&&+item?.financeType===+FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT){
                           history.push(PageEnum.AI_SUB_ORDER + '?id=' + data?.id);
                        }
                      }}
                    >
                      <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
                    </AiOderCardItem>
                  </div>
                );
              })}
            </PullToRefresh>
          </div>
        </div>
        {/*时间筛选器*/}
        <FeaturesDateTime
          open={isOpen}
          onClose={onClose}
          onCancel={onClose}
          onConfirm={(value: any) => {
            const { start, end } = value;
            setTime([start, end] as any);
            onClose();
          }}
        />
      </div>
    </BasePullDownToRefresh>
  );
};
