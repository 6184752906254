import { RightArrow } from '@/assets/icons/mine/RightArrow';
import ProjectSetting from '@/setting/projectSetting';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// 理财列表
enum FINANCIAL_MANAGEMENT_LIST_ENUM {
  // 量化理财
  QUANTIFICATION_FINANCIAL_MANAGEMENT = '0',
  // 定期理财
  REGULAR_FINANCIAL_MANAGEMENT = '1',
}
export default ({
  item,
  children,
  clickOnTheCard,
  isShowRightIcon,
  clickEarlySettlement,
}: IProps) => {
  const { t } = useTranslation();
  const { joinSymbol } = useModel('exchange');
  return (
    <div
      className="cursor-pointer"
      key={item}
      onClick={() => {
        clickOnTheCard && clickOnTheCard?.(item);
      }}
    >
      <div className="flex justify-between items-center mt-2 text-xs ">
        <div className="flex items-center flex-wrap gap-2">
          {/* <span className=" text-base mr-1 rounded-md p-1 pl-0  font-bold">
            {t('AI理财交易档位')}
          </span> */}
          <img
            src={item?.icon}
            className="w-[32px] h-[32px] rounded-full object-cover"
            alt=""
          />
          <div className="text-base text-backContrastColor">
            {item?.name ? item?.name : item?.coinName}
          </div>
        </div>
        <div className="flex flex-wrap items-center">
          {isShowRightIcon && (
            <span
              className={`text-backContrastColor flex items-center rounded-md p-1`}
            >
              <div className=" h-[18px] ml-2 flex items-center justify-end">
                <RightArrow />
              </div>
            </span>
          )}
        </div>
      </div>
      {/* tab  */}
      <div className="flex items-center flex-wrap my-2">
        <span className="h-fit font-normal bg-backgroundAuxiliaryColor rounded-[4px] p-1 text-xs">
          {t('{{date}}天', { date: item?.expirationDate })}
        </span>
        <span
          className={`"h-fit w-fit bg-backgroundAuxiliaryColor ml-1 rounded-[4px] text-xs flex items-center rounded-md p-1 ${
            item?.status === 1
              ? 'border-backContrastColor'
              : 'border-successColor'
          }`}
        >
          {item?.status === 1 && (
            <span className="text-auxiliaryTextColor flex items-center">
              {t('待结算')}
            </span>
          )}
          {item?.status === 0 && (
            <span className="text-successColor flex items-center">
              {t('已结算')}
            </span>
          )}
        </span>
        {/* 类型 */}
        {![null, undefined].includes(item?.financeType) && (
          <span className="h-fit font-normal bg-backgroundAuxiliaryColor text-auxiliaryTextColor ml-1 rounded-[4px] p-1 text-xs">
            {+item?.financeType ===
            +FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT
              ? t('量化理财')
              : t('定期理财')}
          </span>
        )}
      </div>
      <div className="flex justify-between mt-2 text-xs">
        <span className=" text-auxiliaryTextColor">{t('订单号')}</span>
        <span className="text-backContrastColor flex items-center font-bold">
          {item?.orderNo}
        </span>
      </div>
      <div className="flex justify-between mt-2 text-xs">
        <span className=" text-auxiliaryTextColor">{t('购买金额')}</span>
        <span className="text-backContrastColor flex items-center font-bold">
          {/* {joinSymbol({
            price: RenderUtil.FormatAmount(item?.amount, 2, true),
            currency: ProjectSetting.APP_DEFAULT_CURRENCY,
          })} */}
          {
            RenderUtil.FormatAmount(item?.amount, 2, true)+" "+item?.coinName
          }
        </span>
      </div>
      <div className="flex justify-between mt-2 text-xs text-right">
        <span className=" text-auxiliaryTextColor flex-shrink-0">
          {t('开始时间')}
        </span>
        <span className="text-auxiliaryTextColor">
          {RenderUtil.formatDate(item?.buyTime, 'YYYY-MM-DD HH:mm:ss')}
        </span>
      </div>
      <div className="flex justify-between mt-2 text-xs text-right">
        <span className=" text-auxiliaryTextColor flex-shrink-0">
          {t('结束时间')}
        </span>
        <span className="text-auxiliaryTextColor">
          {RenderUtil.formatDate(item?.endTime, 'YYYY-MM-DD HH:mm:ss')}
        </span>
      </div>
      <div className="flex justify-between mt-2 text-xs text-right">
        <span className=" text-auxiliaryTextColor flex-shrink-0">
          {t('到期盈利率')}
        </span>
        <span className="text-auxiliaryTextColor">
          {RenderUtil.FormatAmount(item?.displayMaxProfitRate , 2, false)} %
        </span>
      </div>

      <div className="flex justify-between mt-2 text-xs text-right">
        <span className=" text-auxiliaryTextColor flex-shrink-0">
          {t('盈利金额')}
        </span>
        <span className=" text-successColor font-bold text-sm14">
          {/* {joinSymbol({
            price: RenderUtil.FormatAmount(item?.profit, 2, true),
            currency: ' ' + ProjectSetting.APP_DEFAULT_CURRENCY,
          })} */}
          {
            RenderUtil.FormatAmount(item?.profit, 2, true)+" "+item?.coinName
          }
        </span>
      </div>
      <div className="flex justify-between mt-2 text-xs text-right">
        <span className=" text-auxiliaryTextColor flex-shrink-0">
          {t('到期盈利金额')}
        </span>
        <span className="text-auxiliaryTextColor">
          {RenderUtil.FormatAmount(item?.amount*item?.displayMaxProfitRate/100 , 2, false)+" "+item?.coinName}
        </span>
      </div>
      {/* <div className="flex justify-between mt-2 text-xs">
        <span className=" text-auxiliaryTextColor">{t('交易中')}</span>
        <span className="text-backContrastColor flex items-center font-bold">
          {joinSymbol({
            price: item?.subTodaySum,
            currency: ProjectSetting.APP_DEFAULT_CURRENCY,
          })}
          &nbsp;/&nbsp;
          {joinSymbol({
            price: item?.amount,
            currency: ProjectSetting.APP_DEFAULT_CURRENCY,
          })}
        </span>
      </div>

      <div className="flex justify-between mt-2 text-xs">
        <span className=" text-auxiliaryTextColor">{t('已盈利')}</span>
        <span className="text-backContrastColor flex items-center font-bold">
          {joinSymbol({
            price: item?.profit,
            currency: ProjectSetting.APP_DEFAULT_CURRENCY,
          })}
        </span>
      </div> */}
      {/* { +item?.status !== 0 && (<div className='w-full flex justify-end mt-2'>
          <Button className='!bg-backgroundAuxiliaryColor text-[12px] px-2 py-2 min-h-[32px] rounded-md flex items-center justify-center text-center leading-[10px] break-words text-titleColor cursor-pointer'  onClick={()=>clickEarlySettlement?.(item)}>
            {t('提前结算')}
          </Button>
          </div> )
        } */}
      {children}
    </div>
  );
};

interface IProps {
  item: any;
  clickOnTheCard?: (data: any) => void;
  children?: ReactNode;
  isShowRightIcon?: boolean;
  clickEarlySettlement?: (val: any) => void; // 点击提前结算
}
